import  { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {  useIsFetching, useIsMutating } from "react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import add_card from "../../assets/Images/add_card.png";
import add_card_white from "../../assets/Images/add_card_white.png";
import account_balance_black from "../../assets/Images/account_balance_black.svg";
import add_card_black from "../../assets/Images/add_card_black.svg";
import account_balance_white from "../../assets/Images/account_balance_white.png";
import account_balance from "../../assets/Images/account_balance.png";
import LoaderComponent from "../../components/LoaderComponent";

import showSubscriptionToast from "./SubscriptionToast";
import { useAsaSession } from '../../services/AsaSessionServices';
import { useFintechLayouts, usePaymentMethodsMutation } from '../../services/AsaApiServices';

interface IPaymentMethodInput {
  isDefault: boolean;
  paymentMethodName: string | null;
  paymentType: string;
  routingNumber: string | null;
  accountNumber: string | null;
  ACHName: string | null;
  cardType: string | null;
  firstName: string | null;
  middleName: string;
  lastName: string;
  expirationDate: Date | null;
  CardNumber: string;
  CardHolderName: string;
  CVV: string | null;
  Nickname: string;
}

interface IPaymentMethodError {
  CardNumber: string | null;
  expirationDate: string | null;
  CardHolderName: string | null;
  CVV: string | null;
  accountNumber: string | null;
  routingNumber: string | null;
  Nickname: string | null;
}

const defaultInput: IPaymentMethodInput = {
  isDefault: true,
  paymentMethodName: null,
  paymentType: "",
  routingNumber: null,
  accountNumber: null,
  ACHName: null,
  cardType: null,
  firstName: null,
  middleName: "",
  lastName: "",
  expirationDate: null,
  CardNumber: "",
  CardHolderName: "",
  CVV: null,
  Nickname: "",
};

const defaultError: IPaymentMethodError = {
  CardNumber: null,
  expirationDate: null,
  CardHolderName: null,
  CVV: null,
  accountNumber: null,
  routingNumber: null,
  Nickname: null,
};

const PaymentOption = ({ option, selectedOption, onClick, icon, alt,style }
  :{option:string,selectedOption:string,onClick:()=>void,icon:string,alt:string,style:any}) => (
  <div
    className={`flex-1 p-2  h-[71px] flex items-center justify-center ${
      selectedOption === option
        ? "border-4  bg-[#705AE2]"
        : selectedOption === ""
          ? "border-white bg-[#121212]"
          : "bg-[#121212]"
    } rounded-lg cursor-pointer`}
    onClick={onClick}
    style={style}
  >
    <img
      src={icon}
      className={`w-8 h-8 ${selectedOption === option ? "filter invert" : "text-black"}`}
      alt={alt}
    />
  </div>
);

const InputField = ({ id, label, value, error, onChange, type = "text", placeholder = "" }:
  {id:string,label:string,value:string | null,error:string | null , onChange:(val:any,field:string)=>void,type:string | undefined,placeholder:string }
) => (
  <div className="mb-10 relative">
    <label
      className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
        value
          ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
          : error
            ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
            : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
      }`}
      htmlFor={id}
      style={{
        fontSize: error ? "1rem" : value ? "1.25rem" : "1rem",
      }}
    >
      {label}
    </label>
    <input
      type={type}
      id={id}
      className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-white shadow-sm focus:border-white focus:ring-white sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
        value ? "text-[#FFFFFF]" : ""
      }`}
      style={{
        backgroundColor: "#121212",
        borderColor: "#FFFFFF",
        color: "#FFFFFF",
      }}
      value={value || ""}
      onChange={(e)=>onChange(e,id)}
      placeholder={placeholder}
    />
    {error && (
      <p className="text-red-500 text-left text-xs absolute" style={{ left: "10px" }}>
        {error}
      </p>
    )}
  </div>
);

const DatePickerField = ({ selected, onChange, error, minDate }:
  {selected:Date | null,onChange:(date:Date)=>void,error:string | null,minDate:Date}
) => (
  <div className="custom-date-picker-port relative mb-4 flex-1">
    <label
      className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#121212] px-1 font-poppins ${
        selected
          ? "-top-2.5 text-[#FFFFFF] scale-75 label-shrink-port"
          : error
            ? "top-[-10px] text-[#FFFFFF] -translate-y-1/2 label-expand-port"
            : "top-1/2 text-[#FFFFFF] -translate-y-1/2 label-expand-port"
      }`}
      htmlFor="expirationDate"
      style={{
        fontSize: error ? "1rem" : selected ? "1.25rem" : "1rem",
        zIndex: 1,
      }}
    >
      Expiration
    </label>
    <DatePicker
      id="expirationDate"
      selected={selected}
      onChange={onChange}
      minDate={minDate}
      dateFormat="MM/yyyy"
      placeholderText=""
      className="custom-date-picker mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-white shadow-sm focus:border-white focus:ring-white sm:text-sm pt-2 pb-2 pl-3 pr-3 bg-[#121212]"
      showMonthYearPicker
    />
    {error && (
      <p className="text-red-500 text-left text-xs absolute" style={{ left: "10px" }}>
        {error}
      </p>
    )}
  </div>
);

const FormButton = ({ label, onClick, style }:
  {label:string,onClick:(val:any)=>void,style:any}
) => (
  <button
    className="w-full bg-[#705AE2] text-white py-3 font-poppins font-semibold rounded-md transition duration-300 focus:outline-none focus:ring-2 shadow-custom"
    style={style}
    onClick={onClick}
  >
    {label}
  </button>
);

export default function SubscriptionAddCreditCardScreen() {
  const layout = useFintechLayouts();
  const { data: session } = useAsaSession();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isLoading = isFetching > 0 || isMutating > 0;
  const paymentMethodMutation = usePaymentMethodsMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const paymentMethod = state?.payment;
  const returnUrl=state?.returnUrl;
  
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [formData, setFormData] = useState<IPaymentMethodInput>({ ...defaultInput });
  const [errors, setErrors] = useState<IPaymentMethodError>({ ...defaultError });
  const [errors1, setErrors1] = useState({ accountNumber: null, routingNumber: null });

  const minDate = new Date();

  useEffect(() => {

    if (location.state && location.state.payment) {
      const paymentMethod = location.state.payment;
      const paymentType = paymentMethod.paymentType === "CC" ? "card" : "ACH";
      setSelectedOption(paymentType);
      let formattedCardNumber = paymentMethod.cardNumber
        ? paymentMethod.cardNumber.replace(/-/g, "").slice(0, 16)
        : null;

      if (formattedCardNumber) {
        formattedCardNumber = formattedCardNumber.match(/.{1,4}/g)?.join("-") || "";
      }

      setFormData({
        ...defaultInput,
        ...paymentMethod,
        expirationDate: new Date(
          Number(paymentMethod.expirationYear),
          Number(paymentMethod.expirationMonth) - 1,
          1
        ),
        CardNumber: formattedCardNumber,
        CardHolderName:paymentMethod?.cardHolderName
      });
    }
  }, [location.state, session]);
 

  const handleInputChange = (e:any, fieldName:string) => {
    let value = e.target.value;

    if (fieldName === "CardNumber") {
      value = value.replace(/-/g, "").slice(0, 16);
      if (value.length > 0) {
        value = value.match(/.{1,4}/g)?.join("-") || "";
      }
    }

    setFormData((prev) => ({ ...prev, [fieldName]: value || null }));
    setErrors((prev) => ({ ...prev, [fieldName]: value ? null : `${fieldName} is required` }));
  };

  const handleDateChange = (date:Date) => {
    setFormData((prev) => ({ ...prev, expirationDate: date }));
    setErrors((prev) => ({ ...prev, expirationDate: date ? null : "Expiration Date is required" }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isCardSelected = selectedOption === "card";
    const paymentType = isCardSelected ? "CC" : "ACH";

    const expirationMonth = isCardSelected && formData.expirationDate
      ? String(formData.expirationDate.getMonth() + 1).padStart(2, "0")
      : null;
    const expirationYear = isCardSelected && formData.expirationDate
      ? String(formData.expirationDate.getFullYear())
      : null;

    const dataToSend = {
      ...formData,
      paymentMethodID: location?.state?.payment?.paymentMethodID,
      paymentType,
      expirationMonth,
      expirationYear,
      expirationDate: undefined,
      CardNumber: isCardSelected ? formData.CardNumber.replace(/-/g, "") : null,
      
    };

    try {
      await paymentMethodMutation.mutateAsync(dataToSend);
      if(returnUrl){
        navigate(returnUrl)
      }else{
        navigate(-1)
      }
    } catch (error) {
     console.log(error)
      showSubscriptionToast((error as Error)?.message , true);
    }
  };

  const handleBackClick = () => navigate(-1);

  return (
    <div
      className="bg-gradient-to-b from-[#705AE2] to-[#490C95] h-screen flex flex-col items-center text-center pt-10 relative font-poppins"
      style={layout?.backGroundStyle}
    >
      {isLoading && <LoaderComponent />}
      <div className="absolute top-4 left-4 rounded-lg p-1 w-[40px] h-[40px] flex items-center justify-center">
        <img
          className="cursor-pointer w-[18px] h-[18px] mt-8"
          src={arrow_back_ios_new}
          alt="Back"
          onClick={handleBackClick}
        />
      </div>
      <h1 className="text-white font-bold text-xl">
        {selectedOption === "" && "Select a Payment Method"}
        {selectedOption === "card" && "Add a Credit or Debit Card"}
        {selectedOption === "ACH" && "Add a Checking Account"}
      </h1>
      <div className="bg-[#121212] rounded-t-3xl shadow-md mt-10 w-full max-w-md p-6 flex-grow flex flex-col">
        <div className="flex justify-center mb-6 space-x-4">
          <PaymentOption
            option="card"
            selectedOption={selectedOption}
            onClick={() => {
              setSelectedOption("card");
              setFormData({ ...defaultInput });
              setErrors({ ...defaultError });
            }}
            icon={selectedOption === "card" ? add_card : selectedOption === "" ? add_card_white : add_card_black}
            alt="Card"
            style={layout?.buttonStyle}
          />
          <PaymentOption
            option="ACH"
            selectedOption={selectedOption}
            onClick={() => {
              setSelectedOption("ACH");
              setFormData({ ...defaultInput });
              setErrors({ ...defaultError });
            }}
            icon={selectedOption === "ACH" ? account_balance : selectedOption === "" ? account_balance_white : account_balance_black}
            alt="Bank"
            style={layout?.buttonStyle}
          />
        </div>

        {selectedOption && (
          <form onSubmit={handleFormSubmit}>
            {selectedOption === "card" && (
              <>
                <InputField
                  id="CardNumber"
                  label="Card Number"
                  value={formData.CardNumber}
                  error={errors.CardNumber}
                  onChange={(e) => handleInputChange(e, "CardNumber")}
                  type="tel"
                  placeholder=""
                />
                <div className="flex space-x-4 mb-8">
                  <DatePickerField
                    selected={formData.expirationDate}
                    onChange={handleDateChange}
                    error={errors.expirationDate}
                    minDate={minDate}
                  />
                  <InputField
                    id="CVV"
                    label="CVV"
                    value={formData.CVV}
                    error={errors.CVV}
                    onChange={(e) => handleInputChange(e, "CVV")}
                    type="password"
                    placeholder=""
                  />
                </div>
                <InputField
                  id="CardHolderName"
                  label="Cardholder Name"
                  value={formData.CardHolderName}
                  error={errors.CardHolderName}
                  onChange={(e) => handleInputChange(e, "CardHolderName")}
                  type={"text"}
                  placeholder=""
                />
                <InputField
                  id="Nickname"
                  label="Nickname (optional)"
                  value={formData.Nickname}
                  error={errors.Nickname}
                  onChange={(e) => handleInputChange(e, "Nickname")}
                  placeholder=""
                  type={"text"}
                />
              </>
            )}
            {selectedOption === "ACH" && (
              <>
                <InputField
                  id="accountNumber"
                  label="Account Number"
                  value={formData.accountNumber}
                  error={errors1.accountNumber}
                  onChange={(e) => handleInputChange(e, "accountNumber")}
                  type="tel"
                  placeholder=""
                />
                <InputField
                  id="routingNumber"
                  label="Routing Number"
                  value={formData.routingNumber}
                  error={errors1.routingNumber}
                  onChange={(e) => handleInputChange(e, "routingNumber")}
                  type="tel"
                  placeholder=""
                />
              </>
            )}
            <FormButton
              label={paymentMethod ? "UPDATE" : "SAVE"}
              onClick={handleFormSubmit}
              style={layout?.buttonStyle}
            />
          </form>
        )}
      </div>
    </div>
  );
}